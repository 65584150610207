import { InitialPresence } from '@containers/Chat/services/types/IncomingEvents';
import { SigninEntity } from '@containers/OnBoarding/services/types/signin.entity';
import { UserEntity } from '@containers/Settings-And-Members/services/entity/account.entity';
import {
  BillingEntity,
  EnabledModuleType,
  UserPreferencesEntity,
  WorkspaceEntity,
  WorkspaceUsersEntity,
} from '@containers/Settings-And-Members/services/entity/workspace.entity';
import { SETTINGS_REDIRECTION_ROUTE } from '@containers/TaskModule/utils/localstorage.utils';
import { getStoreClass } from '@poly-state/core';
import { createStoreSelector } from '@poly-state/react';
import { clearIndexedDB } from '@utils';
import { createContext, useContext } from 'react';
import { globalStoreCookie } from './global-store-cookie.service';

type GlobalStoreType = {
  authenticated: boolean;
  accessToken: string;
  refreshToken: string;
  workspaces: WorkspaceEntity[];
  currentWorkspace: WorkspaceEntity | null;
  billingInfo: BillingEntity | null;
  user: UserEntity | null;
  showOverly: boolean;
  language: 'en' | 'no';
  userPreferences: UserPreferencesEntity | null;
  isSwitchingWorkspace: boolean;
};

export const globalStoreInitialState: GlobalStoreType = {
  authenticated: false,
  accessToken: '',
  refreshToken: '',
  workspaces: [],
  currentWorkspace: null,
  billingInfo: null,
  user: null,
  showOverly: false,
  language: 'en',
  userPreferences: null,
  isSwitchingWorkspace: false,
};

export class GlobalStore extends getStoreClass<GlobalStoreType>() {
  constructor(init: GlobalStoreType = globalStoreInitialState) {
    super(init);
  }

  onWorkspaceSelection = (workspace: WorkspaceEntity) => {
    this.setState((p) => ({ ...p, currentWorkspace: workspace, isSwitchingWorkspace: true }));

    const { accessToken, refreshToken } = this.getState();

    globalStoreCookie.setGlobalStoreCookie(() => {
      return {
        selectedCompanyID: workspace._id,
        accessToken,
        refreshToken,
      };
    });
  };

  onLogin = (res: SigninEntity) => {
    this.setState((p) => ({
      ...p,
      authenticated: true,
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
      workspaces: res.workspaces,
      user: res.user,
    }));

    globalStoreCookie.setGlobalStoreCookie(() => {
      return {
        selectedCompanyID: null,
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
      };
    });
  };

  onLogout = () => {
    console.trace('onLogout');
    this.setState(globalStoreInitialState);
    globalStoreCookie.setGlobalStoreCookie(() => null);
    localStorage.removeItem(SETTINGS_REDIRECTION_ROUTE);
    clearIndexedDB();
  };

  onSetUserInitialPresence = (payload: InitialPresence[]) => {
    const onlineUsersId: string[] = [];
    payload.forEach((user) => {
      if (user?.status === 'online') {
        onlineUsersId.push(user.userID);
      }
    });

    const userWithPresence: WorkspaceUsersEntity[] = this.getState().currentWorkspace!.users.map((user) => {
      if (onlineUsersId.includes(user?.userID?._id)) {
        return {
          ...user,
          userID: {
            ...user.userID,
            status: 'online',
          },
        };
      } else {
        return {
          ...user,
          userID: {
            ...user.userID,
            status: 'offline',
          },
        };
      }
    });
    this.setState((p) => ({
      ...p,
      currentWorkspace: {
        ...p.currentWorkspace!,
        users: userWithPresence,
      },
    }));
  };

  onUpdateUserPresence = (payload: InitialPresence) => {
    const userWithPresence: WorkspaceUsersEntity[] = this.getState().currentWorkspace!.users.map((user) => {
      if (user?.userID?._id === payload?.userID) {
        return {
          ...user,
          userID: {
            ...user.userID,
            status: payload?.status,
          },
        };
      } else {
        return user;
      }
    });
    this.setState((p) => ({
      ...p,
      currentWorkspace: {
        ...p.currentWorkspace!,
        users: userWithPresence,
      },
    }));
  };

  onUpdateSidebarOrder = (sidebarOrder: EnabledModuleType[]) => {
    this.setState((p) => ({
      ...p,
      userPreferences: {
        ...p.userPreferences!,
        sidebarOrder,
      },
    }));
  };
}

export const GlobalStoreContext = createContext<GlobalStore | null>(null);

export const useGlobalStore = () => {
  const store = useContext(GlobalStoreContext);
  if (!store) {
    throw new Error('useGlobalStore must be used within a GlobalStoreProvider.');
  }

  const select = createStoreSelector(store);

  return { store, select };
};

export const useGlobalStoreSelector = <U>(fn: (store: GlobalStoreType) => U): U => {
  const { select } = useGlobalStore();

  return select(fn);
};
